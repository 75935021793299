import React from "react";
import { useState } from "react";
function ErrorAlert({ message }: { message: string }) {
  if (message === "") {
    return "";
  }
  return <div className="alert alert-danger">{message}</div>;
}

export default function DownloadId() {
  const [input, setInput] = useState(
    window.location.pathname === "/" || window.location.pathname.startsWith("/users/") || window.location.pathname.startsWith("/u/")
      ? ""
      : `https://splits.io${window.location.pathname}`
  );
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  function handleInputChange(e) {
    setInput(e.target.value);
  }

  async function handleClick(e) {
    e.preventDefault();
    setLoading(true);

    var i = input;
    if (i.length === 0) {
      setError("Please enter a URL.");
      setLoading(false);
      return;
    }
    if (i.substring(0, "https://".length) !== "https://") {
      i = `https://${i}`;
    }
    if (i.substring(0, "https://splits.io/".length) !== "https://splits.io/") {
      setError("Make sure your link starts with https://splits.io/");
      setLoading(false);
      return;
    }

    const u = new URL(i);
    if (u.pathname.startsWith("/users/") || u.pathname.startsWith("/u/")) {
      setError("To download all of your splits, please type your username in the second box.");
      setLoading(false);
      return
    }
    const id36 = u.pathname.substring(1);
    const id10 = parseInt(id36, 36);

    try {
      const resp = await fetchSplits(id10)
      const link = document.createElement("a");
      link.href = URL.createObjectURL(resp.body);
      link.download = resp.filename;
      document.body.appendChild(link); // Required for Firefox
      link.click();
      setLoading(false);
      setInput("");
    } catch (err) {
      setError(err.message)
      setLoading(false)
    }
  }

  return (
    <div>
      <ErrorAlert message={error} />
      <p style={{ marginTop: "1rem" }}>
        Enter a Splits.io URL to download splits:
      </p>
      <form onSubmit={handleClick}>
        <input
          autoFocus
          onChange={handleInputChange}
          placeholder="https://splits.io/gcb"
          type="text"
          value={input}
        />
        <button disabled={loading} type="submit">
          {loading ? "..." : "Download"}
        </button>
      </form>
    </div>
  );
}

export async function fetchSplits(id10: number): Promise<{ filename: string, body: Blob }> {
  return fetch(`https://s3.amazonaws.com/splits.io-runid-to-s3filename/by_id10/${id10}.json`)
    .then((resp) => {
      return resp.json()
    })
    .then(async (runInfo) => {
      if (runInfo.s3_filename === undefined) {
        throw new Error('Run not found. Error code 6')
      }

      let extension = "";

      switch (runInfo.program.toLowerCase()) {
        case "splits.io exchange format":
          extension = "json"
          break
        case "facesplit":
          extension = "fss"
          break
        case "floating speedrun timer":
          extension = "json"
          break
        case "frameperfect":
          extension = "json"
          break
        case "livesplit":
          extension = "lss"
          break
        case "llanfair (gered's fork)":
          extension = "lfs"
          break
        case "llanfair":
          extension = "llanfair"
          break
        case "llanfair rewrite":
          extension = "llanfair2"
          break
        case "portal 2 live timer":
          extension = "csv"
          break
        case "shitsplit":
          extension = "ss"
          break
        case "sourcelivetimer":
          extension = "sourcelivetimer"
          break
        case "splitterz":
          extension = "splitterz"
          break
        case "splitty":
          extension = "json"
          break
        case "time split tracker":
          extension = "timesplittracker"
          break
        case "urn":
          extension = "json"
          break
        case "worstrun":
          extension = "json"
          break
        case "wsplit":
          extension = "wsplit"
          break
      }

      const formattedDate = new Date(runInfo.created_at).toISOString().split('T')[0];
      let id36 = runInfo.id.toString(36);
      let filename = `${formattedDate} ${id36}`
      if (extension !== "") {
        filename = `${filename}.${extension}`
      }

      const categoryInfo = await fetch(`https://s3.amazonaws.com/splits.io-runid-to-s3filename/categories/by_id/${runInfo.category_id}.json`)
        .then((resp) => resp.json())
        .catch((err) => {
          throw new Error(`Category info not found. Error code 4 (${err})`);
        });

      const gameInfo = await fetch(`https://s3.amazonaws.com/splits.io-runid-to-s3filename/games/by_id/${categoryInfo.game_id}.json`)
        .then((resp) => resp.json())
        .catch((err) => {
          throw new Error(`Game info not found. Error code 3 (${err})`);
        });

      if (gameInfo && gameInfo.name && categoryInfo && categoryInfo.name) {
        filename = `${gameInfo.name} ${categoryInfo.name} - ${filename}`.replace(/[<>:"/\\|?*]/g, '');
      }

      return fetch(`https://s3.amazonaws.com/splits.io/splits/${runInfo.s3_filename}`)
        .then((resp) => resp.blob())
        .then(async (splits) => {
          if (splits === undefined) {
            throw new Error("splits file response was blank");
          }
          console.log(splits.text())
          if ((await splits.text()).includes("AccessDenied")) {
            throw new Error("That file doesn't exist; please check your link. Error code 5")
          }
          return { filename, body: splits }
        })
        .catch((err) => {
          throw new Error(
            `Splits not found. Are you sure you have the right link? Error code 1 (${err})`
          );
        });
    })
}
