import React from "react";

import DownloadId from "./DownloadId.tsx";
import DownloadUser from "./DownloadUser.tsx";

export default function Download() {
  return (
    <>
      <p style={{ marginTop: "4rem" }}>You can still retrieve your data.</p>
      <DownloadId />
      <DownloadUser />
      <br />
      <br />
      <p>
        If you have more complex archival needs than this,
        <br />
        <a href="mailto:ben@twos.dev">email me</a> and I'll see what I can do.
        <br />
        All data is backed up.
        <br />
      </p>
      <br />
    </>
  )
}
