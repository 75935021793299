import logo from "./logo.svg";
import "./App.css";
import Download from "./Download.tsx";
import ComingSoon from "./ComingSoon.tsx";
import React from "react";

const ARCHIVE_COMPLETED = true;

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <img src={logo} className="App-logo" alt="logo" />
          <br />
          <br />
          Splits.io has shut down permanently for financial reasons.
        </p>
        <p>
          For more info, see my{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twos.dev/splitsio.html"
          >
            blog post
          </a>
          .<br />
          For an alternative to Splits.io, see{" "}
          <a target="_blank" rel="noreferrer" href="https://therun.gg">
            The Run
          </a> or <a target="_blank" rel="noreferrer" href="https://saltysplits.com">SaltySplits</a>
          .
        </p>
        {ARCHIVE_COMPLETED ? <Download /> : <ComingSoon />}
        <p>
          Splits.io remains{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/glacials/splits-io"
          >
            open source
          </a>
          .<br />
          Anyone who wants to spin up a copy has my blessing.
        </p>
        <br />
        <br />
        <p>
          Thank you for 11 years of going fast.
          <div style={{ textAlign: "left" }}>
            <br />
            —Ben "Glacials" Carlsson (<a href="https://twos.dev">twos.dev</a>)
            <br />
            —BatedUrGonnaDie (
            <a href="https://leagueofnewbs.com/">leagueofnewbs.com</a>)
            <br />
            —Franklyn "Tuna" Rodriguez
          </div>
        </p>
        <p>
          <a
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
            href="https://www.reddit.com/r/speedrun/comments/1r3buu/i_made_a_pastebinlike_site_for_splits_any_use/"
          >
            ❤️
          </a>
        </p>
        <br />
        <br />
        <pre>
          Final stats:
          <br />
          542,309 runs
          <br />
          10,186,540 splits
          <br />
          {/* 230,728,890 history splits */}
          125,765 users
          <br />
          62,891 games
          <br />
          <br />
          <br />
          <br />
          <br />
          2013–2025
        </pre>
      </header>
    </div>
  );
}

export default App;
