import JSZip from "jszip";
import React from "react";
import { useState } from "react";
import { fetchSplits } from "./DownloadId.tsx";

function Error({ message }) {
  if (message === "") {
    return "";
  }
  return <div className="alert alert-danger mt-5">{message}</div>;
}

export default function DownloadUser() {
  const [input, setInput] = useState(
    (window.location.pathname.startsWith("/users/")
      ? window.location.pathname.slice("/users/".length)
      : (
        window.location.pathname.startsWith("/u/")
          ? window.location.pathname.slice("/u/".length)
          : ""
      )
    )
  );
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState("");

  function handleInputChange(e) {
    setInput(e.target.value);
  }

  async function handleClick(e) {
    e.preventDefault();
    setLoading(true);

    const username = input.toLowerCase();
    setDownloadStatus(`Fetching runs for ${username}\n`);
    console.log(`Fetching user ${username}`);
    fetch(
      `https://s3.amazonaws.com/splits.io-runid-to-s3filename/by_username/${username}.json`
    )
      .then((resp) => resp.json())
      .then(async (user: { [key: string]: { id: number, user_id, number, created_at: string, updated_at: string, category_id: number } } | undefined) => {
        if (user === undefined) {
          setError("user response was blank");
          setLoading(false);
          return;
        }

        // const runsByUser: { [key: string]: { [key: string]: any } } = {};
        const runs = Object.values(user)
        console.log(`Found ${runs.length} user runs:`)
        setDownloadStatus(`Found ${runs.length} runs`);

        const zip = new JSZip();
        const dir = zip.folder(`splitsio-${username}`);
        if (dir === null) {
          setError("Cannot build zip file. Please try again or with a different browser.")
          setLoading(false);
          return
        }
        for (const num in runs) {
          const run = runs[num]
          const id10 = run.id
          const id36 = id10.toString(36);
          const percentage = ((parseInt(num) + 1) / runs.length * 100).toFixed(0);
          const progressBarLength = 20;
          const filledLength = Math.round((parseInt(percentage) / 100) * progressBarLength);
          const progressBar = `[${'='.repeat(filledLength)}${' '.repeat(progressBarLength - filledLength)}]`;
          setDownloadStatus(`${progressBar} ${percentage}%`);
          console.log(`Fetching ${id36}`)
          try {
            const splits = await fetchSplits(id10)
            dir.file(splits.filename, splits.body)
          } catch (err) {
            console.error(`cannot fetch ${id36}: ${err}`)
          }
        }
        setDownloadStatus(`Building zip file`);
        zip.generateAsync({ type: "blob" }).then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `splitsio-${username}.zip`;
          document.body.appendChild(link); // Required for Firefox
          link.click();
          setLoading(false);
          setInput("");
          setDownloadStatus("Download complete");
        });
      })
      .catch((err) => {
        setError(
          `Splits not found. Are you sure you have the right username? Error code 4 (${err})`
        );
        setLoading(false);
      });
  }

  return (
    <div>
      <Error message={error} />
      <p style={{ marginTop: "1rem" }}>
        Or enter your username to download a zip file of all your splits:
      </p>
      <form onSubmit={handleClick}>
        <input
          autoFocus
          onChange={handleInputChange}
          placeholder="glacials"
          type="text"
          value={input}
        />
        <button disabled={loading} type="submit">
          {loading ? "..." : "Download"}
        </button>
        <br />
        <pre>{downloadStatus}</pre>
      </form>
    </div>
  );
}
